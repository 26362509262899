import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/logo3.png";
import websiteDevelopmentLogo from "../../Assets/websiteDevelopmentLogo.svg";
import databaseDevelopmentLogo from "../../Assets/databaseDevelopmentLogo.svg";
import costumeSoftwareSolutionLogo from "../../Assets/costumeSoftwareSolutionLogo.svg";
import consultingServicesLogo from "../../Assets/consultingServicesLogo.svg"; 
import Tilt from "react-parallax-tilt";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

function Home2() {
  const { t } = useTranslation();

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.2em", textAlign: "left", fontFamily: "Arial" }}>
              {t('Your One-Stop')} <span className="white"> {t('Solution')} </span> {t('for All Tech and Software Needs')}
            </h1>
            <p className="home-about-body" style={{ fontSize: "0.9em", textAlign: "left", fontFamily: "Arial" }}>
              {t('Expert Website Development, Database Solutions, POS Systems, and More')}
              <br />
              <br />{t('At Code Commerce,')}
              <i>
                <b className="white"> {t('we offer a wide range of technology and software services to help your business thrive in the digital age.')} </b>
              </i>
              <br />
              <br />
              {t('From creating stunning websites to developing robust databases and')} &nbsp;
              <i>
                <b className="white">{t('implementing efficient POS systems,')} </b> {t('our team of experts is here to support')} {" "}
                <b className="white">
                {t('all your tech needs.')}
                </b>
              </i>
              <br />
              <br />
              {t('Explore our full range of services and discover how we can help your business succeed in the digital world.')}<b className="white"> {t('Connect')}  </b> {t('with')} 
              <i>
                <b className="white">
                  {" "}
                  {t('us')} 
                </b>
              </i>
              &nbsp; {t('on')} 
              <i>
                <b className="white"> {t('social media and get in touch for personalized tech solutions.')}</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar d-flex justify-content-center">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar"/>
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="home-about-social">
            <h1>{t('List of Services')}</h1>
            <ul>
              <li className="d-flex align-items-center">
                <img src={websiteDevelopmentLogo} alt={t('Website Development')} className="white" style={{ width: "80px", height: "80px" }} />
                <b className="white" style={{ marginLeft: "10px" }}>  - {t('Website Development')} </b>
              </li>
              <li className="d-flex align-items-center">
                <img src={databaseDevelopmentLogo} alt={t('Database Development')} className="white" style={{ width: "80px", height: "80px" }} />
                <b className="white" style={{ marginLeft: "10px" }}>  - {t('Database Development')} </b>
              </li>
              <li className="d-flex align-items-center">
                <img src={costumeSoftwareSolutionLogo} alt={t('Costume Software Solution')} className="white" style={{ width: "80px", height: "80px" }} />
                <b className="white" style={{ marginLeft: "10px" }}>  - {t('Costume Software Solution')} </b>
              </li>
              <li className="d-flex align-items-center">
                <img src={consultingServicesLogo} alt={t('Consulting Services')} className="white" style={{ width: "80px", height: "80px" }} />
                <b className="white" style={{ marginLeft: "10px" }}>  - {t('Consulting Services')}</b>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>{t('CONTACT US')}</h1>
            <p>
              {t('PHONE')}: <span className="white" style={{ fontFamily: "Arial" }}>771-692-0011  </span> 
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/codecommerce23?igsh=ZGZ0dXZuMXR4emRi&utm_source=qr"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=61560317098583&mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;