import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from 'react-i18next';

function AboutCard() {
  const { t } = useTranslation();
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <br />
            {t('Founded in 2024, Code Commerce is a dynamic team of computer engineers and software engineers committed to delivering top-notch technological solutions. Our team is not only academically accomplished but also brings a wealth of practical experience to the table. Each member of our team has spent years in the field, honing their skills and perfecting their craft.')}
            <br />
            {t('At Code Commerce, we believe in combining academic rigor with real-world expertise to provide the best possible outcomes for our clients. Our deep understanding of technology and industry trends allows us to create innovative solutions that are both efficient and effective.')}
            <br />
            <br />
            {t('Whether you need a website, a database, or a custom software solution, Code Commerce is here to help. We pride ourselves on our ability to understand your needs and deliver solutions that exceed your expectations.')}
            <br />
            <br />
            {t('Join us on our journey as we continue to push the boundaries of what\'s possible in the world of technology. With Code Commerce, you\'re not just getting a service; you\'re gaining a partner dedicated to your success.')}
            <br />
          </p>
          {/* <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
