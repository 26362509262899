import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { AiOutlineHome, AiOutlineUser, AiOutlineGlobal } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.dir = i18n.language === 'ar' || i18n.language === 'ku' ? 'rtl' : 'ltr';
    document.body.style.direction = i18n.language === 'ar' || i18n.language === 'ku' ? 'rtl' : 'ltr';
  }, [i18n.language]);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <span style={{ color: "white", fontSize: "1.5rem" }}>{t('Code')}</span>
          <span className="purple" style={{ fontSize: "1.5rem" }}>{t('Commerce')}</span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`ms-${i18n.language === 'ar' || i18n.language === 'ku' ? '0' : 'auto'} me-${i18n.language === 'ar' || i18n.language === 'ku' ? 'auto' : '0'}`} defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> {t('Home')}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> {t('About')}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Dropdown as={Nav.Item} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Dropdown.Toggle as={Nav.Link} className="language-dropdown">
                  <AiOutlineGlobal style={{ marginBottom: "2px" }} /> {t('Language')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage('ku')}>کوردی</Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage('ar')}>العربية</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;